import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { EmbedPlacement, getEmbedAreas } from '@debbie/common/dist/embeds'
import { c, h } from '@debbie/cortex/dist'
import { useCreditorWebDashboard } from '@debbie/cortex/dist/insights'
import { RootState } from '@debbie/cortex/dist/reducer'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'
import {
  Panel,
  PlaceholderLoader,
  EmbedsFrame,
} from '@debbie/iris/dist/components'
import { Col, Row } from '@debbie/iris/dist/components/Grid'
import PausedCasesPanel from '@debbie/iris/dist/components/PausedCasesPanel'

import CaseStatuses from '../components/charts/CaseStatuses'
import ResolveDuration from '../components/charts/ResolveDuration'
import { SUCCESS, DANGEROUS, GRID_SPACING } from '../theme'

const Dashboard = () => {
  const tenant = useSelector(selectTenant)
  const creditorId = useSelector((s: RootState) => s.selectedCreditor)
  const creditorIds = useSelector((s: RootState) =>
    s.caseFilter ? s.caseFilter.creditorIds : [],
  )
  const lang = useSelector((s: RootState) => s.lang)
  const dashboardData =
    creditorIds[0] && useCreditorWebDashboard(creditorIds[0])
  const dashboard = h.hasData(dashboardData) ? dashboardData.data : null

  const stats = dashboard && dashboard.general
  const totalEndedCases =
    stats && stats.endedCases.reduce((current, next) => current + next.count, 0)
  const totalStatuses =
    stats &&
    stats.statusesGrouped.reduce((current, next) => current + next.count, 0)
  const totalReasons =
    stats &&
    stats.endReasonsGrouped.reduce((current, next) => current + next.count, 0)

  const embedAreas = tenant
    ? getEmbedAreas(tenant.embeds, EmbedPlacement.CreditorWebFrontPage)
    : []

  return dashboard ? (
    <>
      <Col size="md" width="6">
        {creditorId && <PausedCasesPanel />}
        {totalEndedCases > 0 && (
          <Panel header={lang.overview.settlementTime}>
            <PieChartContainer>
              <ResolveDuration data={stats.endedCases} />
            </PieChartContainer>
          </Panel>
        )}
      </Col>
      <Col size="md" width="6">
        {embedAreas &&
          creditorIds[0] &&
          embedAreas.map((embedArea) => (
            <Panel
              key={embedArea.embedId}
              header={embedArea.title}
              bodyStyle={{ overflow: 'hidden' }}
            >
              <div
                style={{
                  margin: '-20px -20px -25px -20px',
                }}
              >
                <EmbedsFrame
                  id={embedArea.embedId}
                  height={
                    embedArea.placement === EmbedPlacement.CreditorWebFrontPage
                      ? embedArea.details.height
                      : undefined
                  }
                  context={{
                    creditorId: creditorIds[0],
                  }}
                />
              </div>
            </Panel>
          ))}
        <Panel header={lang.overview.statistics}>
          <StatsPanel>
            {!!dashboard.general.avgEndDays && (
              <p className="normal">
                {lang.overview.averageIntro}{' '}
                <b>{dashboard.general.avgEndDays}</b>{' '}
                {lang.overview.averageOutro}
              </p>
            )}
            <Row>
              <Col size="md" width="6">
                <p>{lang.overview.quantityCases}</p>
                <h3 className="this-one">{stats.casesCount}</h3>
              </Col>
              <Col size="md" width="6">
                <p>{lang.overview.quantifyCustomers}</p>
                <h3 className="this-one">{stats.customersCount}</h3>
              </Col>
            </Row>
          </StatsPanel>
        </Panel>
        {(totalStatuses > 0 || totalReasons > 0) && (
          <Panel header={lang.overview.statusDistribution}>
            <PieChartContainer>
              <CaseStatuses
                statusesData={stats.statusesGrouped}
                reasonsData={stats.endReasonsGrouped}
              />
            </PieChartContainer>
          </Panel>
        )}
      </Col>
    </>
  ) : (
    <>
      <Col size="md" width="5" style={{ marginTop: '10px' }}>
        <PlaceholderLoader style={{ marginBottom: '15px' }} width="70%" />
        <PlaceholderLoader style={{ marginBottom: '15px' }} width="20%" />
        <PlaceholderLoader width="25%" />
      </Col>
      <Col size="md" width="5" style={{ marginTop: '10px' }}>
        <PlaceholderLoader style={{ marginBottom: '15px' }} width="70%" />
        <PlaceholderLoader style={{ marginBottom: '15px' }} width="20%" />
        <PlaceholderLoader width="25%" />
      </Col>
    </>
  )
}

export default Dashboard

const PieChartContainer = styled.div`
  height: 400px;
`

const StatsPanel = styled.div`
  p {
    :not(.normal) {
      color: grey;
      margin: 0;
    }
  }
  margin-bottom: ${GRID_SPACING}px;
  .this-one {
    font-size: 2.3em;
    margin: 0.1em 0 0 0;
    .meta {
      font-size: 0.35em;
      display: inline-block;
      margin-left: 5px;

      > span {
        display: block;
      }

      & > span:last-child {
        font-size: 0.7em;
      }
    }
    .positive {
      color: ${SUCCESS};
    }
    .negative {
      color: ${DANGEROUS};
    }
  }
`
