import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { SocketEvent } from '@debbie/common/dist/web-sockets'
import { QueuedTodo, UpdateReferenceTypes } from '@debbie/common/dist/updates'
import { h } from '@debbie/cortex/dist'

import q from '@debbie/cortex/dist/query'
import { RootState } from '@debbie/cortex/dist/reducer'
import { socket } from '@debbie/cortex/dist/socket'
import { selectUpdateTypesMap } from '@debbie/cortex/dist/update-types/selectors'
import { todosKey, useTodos } from '@debbie/cortex/dist/updates'
import {
  Content,
  PageBase,
  PlaceholderLoader,
  TopNav,
} from '@debbie/iris/dist/components'
import UpdateTimeTracker from '@debbie/iris/dist/components/TodoQueue/UpdateTimeTracker'
import Queue from '@debbie/iris/dist/components/TodoQueue/Queue'

import CaseSummary from '../components/CaseSummary'
import { selectCaseById } from '@debbie/cortex/dist/cases/selectors'
import { selectCustomerById } from '@debbie/cortex/dist/customers/selectors'
import { selectCreditorById } from '@debbie/cortex/dist/creditors/selectors'
import { selectCaseUpdates } from '@debbie/cortex/dist/case/selectors'
import { selectPaymentPlans } from '@debbie/cortex/dist/paymentPlans/selectors'
import { selectCaseVouchers } from '@debbie/cortex/dist/case-vouchers/selectors'
import CaseLoader from '@debbie/iris/dist/components/CaseLoader'

const TodoQueue = () => {
  const { id, updateTypeId } = useParams<{
    id?: string
    updateTypeId: string
  }>()

  const updateTypesMap = useSelector(selectUpdateTypesMap)
  const caseFilter = useSelector((s: RootState) => s.caseFilter)
  const creditorId = useSelector((s: RootState) => s.selectedCreditor)

  const todos = useTodos(updateTypeId, caseFilter, creditorId)

  const [minimized, setMinimized] = useState<boolean>(false)

  useEffect(() => {
    const eventHandler = (
      referenceId: string,
      updateId: string,
      _updateTypeId: string,
    ) => {
      if (updateTypeId === _updateTypeId) {
        q.mutateCache<QueuedTodo[]>(
          todosKey(updateTypeId, caseFilter),
          (todos) => {
            return todos.filter((u) => u.referenceId !== referenceId)
          },
        )
      }
    }

    socket.on(SocketEvent.UPDATECONTINUED, eventHandler)

    return () => {
      socket.removeListener(SocketEvent.UPDATECONTINUED, eventHandler)
    }
  }, [todos])

  const updateType = updateTypesMap[updateTypeId]

  return (
    <div>
      {id && <UpdateTimeTracker id={id} updateTypeId={updateTypeId} />}
      <TopNav />
      <ContentMoveToLeft moveToLeft={!minimized}>
        <Content>
          <PageBase>
            {updateType && h.hasData(todos) ? (
              <>
                {(() => {
                  if (!id) return null

                  switch (updateType.referenceType) {
                    case UpdateReferenceTypes.CASE:
                      return <CaseTodo id={id} />
                    case UpdateReferenceTypes.CREDITOR:
                      return <h3>Todos not handled for creditors yet</h3>
                    default:
                      return null
                  }
                })()}
                <Queue
                  updateType={updateType}
                  updates={todos.data}
                  id={id}
                  onMinimize={(_minimized) => setMinimized(_minimized)}
                />
              </>
            ) : (
              <PlaceholderLoader />
            )}
          </PageBase>
        </Content>
      </ContentMoveToLeft>
    </div>
  )
}

const ContentMoveToLeft = styled.div<{
  moveToLeft?: boolean
}>`
  transition: 0.1s padding;
  ${(props) =>
    props.moveToLeft
      ? `
        @media (min-width: 1500px) {
          padding-right: 255px;
        }
      `
      : ''}
`

export default TodoQueue

const CaseTodo = (props: { id: string }) => {
  const singleCase = useSelector((s: RootState) => selectCaseById(s, props.id))

  const customer = useSelector((s: RootState) =>
    singleCase ? selectCustomerById(s, singleCase.customerId) : null,
  )
  const creditor = useSelector((s: RootState) =>
    singleCase ? selectCreditorById(s, singleCase.creditorId) : null,
  )
  const updates = useSelector((s: RootState) =>
    singleCase ? selectCaseUpdates(s, singleCase.caseId) : null,
  )
  const paymentPlans = useSelector((s: RootState) =>
    singleCase ? selectPaymentPlans(s, singleCase.caseId) : null,
  )
  const caseVouchers = useSelector((s: RootState) =>
    singleCase ? selectCaseVouchers(s, singleCase.caseId) : null,
  )

  const caseIsLoading = !(
    singleCase &&
    creditor &&
    customer &&
    updates &&
    paymentPlans &&
    caseVouchers
  )

  return (
    <>
      <CaseLoader noRedirect id={props.id} />
      {caseIsLoading ? (
        <PlaceholderLoader />
      ) : (
        <CaseSummary id={singleCase.id} />
      )}
    </>
  )
}
